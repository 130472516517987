import './styled.css';
import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';

import PixImg from '../../assets/images/pix.png';
import CartoesImg from '../../assets/images/cartoes.png';
import Loading from '../../assets/images/loading.gif';

import Header from '../../components/header';
import { Location } from '../../components/location';
import { Attendee } from '../../components/attendee';
import { Token } from '../../components/token';
import { Customer } from '../../components/customer';
import { Pix } from '../../components/pix';
import { Modal } from '../../components/modal';
import { WarningAdBlock } from '../../components/warningadblock';
import TicketDescription from '../../components/ticketDescription';

import { TransactionContext } from '../../contexts/TransactionContext';

import { useError } from '../../hooks/useError';
import { useModelDefault } from '../../hooks/useModelDefault';
import { useList } from '../../hooks/useList';
import { useMask } from '../../hooks/useMask';
import { usePaymentOption } from '../../hooks/usePaymentOption';
import { useVar } from '../../hooks/useVar';

import { transactions } from '../../utils/service/payment';
import { calcPromo, calCart, calcCart } from '../../utils/service/product';

import { useTranslation } from 'react-i18next';

export default function Payment() {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const toggle = () => setIsOpenModal(false);
  const [urlTicket, setUrlTicket] = useState('');
  const handleOpenModal = (url) => {
    setUrlTicket(url);
    setIsOpenModal(true);
  };
  const [newInstallments, setNewInstallments] = useState({});

  const {
    selectedProduct,
    setTransactionResponse,
    setPaymentType,
    transactionResponse,
    paymentType,
    setRemainingTime,
    cart,
    nextStep,
    prevStep,
    step,
    installments,
    setInstallments,
    currentLanguage,
    toHome,
  } = useContext(TransactionContext);

  const {
    attendeeDefault,
    cctokenDefault,
    customerDefault,
    transactionDefault,
  } = useModelDefault();

  const {
    attendeeError,
    customerError,
    customerFreeError,
    cctokenError,
    goToFieldWithError,
  } = useError();

  const { PIX, CREDITCARD, FREE } = usePaymentOption();

  const { categories } = useList();

  const { brlMask, usdMask } = useMask();

  const { testMode, accountId } = useVar();

  const [attendees, setAttendees] = useState([]);

  const [transaction, setTransaction] = useState(transactionDefault);
  const [customer, setCustomer] = useState(customerDefault);
  const [cctoken, setCctoken] = useState(cctokenDefault);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [htmlId, setHtmlId] = useState('');
  const [currentPromo, setCurrentPromo] = useState('');
  const [currentPrice, setCurrentPrice] = useState();
  const [promocodeLoading, setPromoCodeLoading] = useState(false);
  const [promocodeError, setPromoCodeError] = useState(false);
  const [free, setFree] = useState(false);
  const [currentPriceColor, setCurrentPriceColor] = useState('#fffae3');
  const [showQrCode, setShowQrCode] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);

  const enablePromocode = true;
  const [newMenu, setNewMenu] = useState({});

  const validateAttendee = (index) => {
    let errorFirstFor = false;
    let errorSecondFor = false;
    const attendee = attendees[index];
    for (let property in attendeeError) {
      const id = `attendee-${index}-${property}`;
      // console.log('validateAttendee', `${id}-error`);
      let field = attendeeError[property];
      for (let current in field) {
        const response = field[current.toString()].func(attendee, index);
        // console.log('response', response);
        if (response) {
          const msg = `fields.attendee.${property}`;
          setHtmlId(id);
          setMessage(t(msg));

          document.getElementById(`${id}-error`).textContent = t(msg);
          goToFieldWithError(id);
          //openModal();
          errorSecondFor = true;
          errorFirstFor = true;
          break;
        } else {
          const element = document.getElementById(`${id}-error`);
          if (element) {
            element.textContent = '';
          }
        }
      }
      if (errorSecondFor) {
        break;
      }
    }

    return !errorFirstFor;
  };

  const validate = (model, error, name) => {
    let errorFor = false;
    for (let property in error) {
      const id = `${name}-${property}`;
      const field = error[property];
      for (let current in field) {
        // console.log(current);
        // console.log(field[current.toString()]);
        // console.log('validate', `${id}-error`);
        if (field[current.toString()].func(model)) {
          setHtmlId(id);
          const msg = `fields.${name}.${property}`;
          document.getElementById(`${id}-error`).textContent = t(msg);
          setMessage(t(msg));
          //openModal();
          goToFieldWithError(id);
          errorFor = true;
          break;
        } else {
          const element = document.getElementById(`${id}-error`);
          if (element) {
            element.textContent = '';
          }
        }
      }
      if (errorFor) {
        break;
      }
    }
    return !errorFor;
  };

  const [adblock, setAdBlock] = useState(false);

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    goToFieldWithError(htmlId);
  };

  const closeModalPromoCode = () => {
    setPromoCodeError(false);
    goToFieldWithError('current-promo');
  };
  const openModal = () => setOpen(true);

  const createPaymentToken = async () => {
    if (transaction.paymentType !== CREDITCARD) return '';

    const name = cctoken.name.split(' ');

    const firstName = name.shift();
    const lastName = name.pop();

    const expireAt = cctoken.expireAt.split('/');
    const month = expireAt.shift();
    const year = expireAt.pop();

    window.Iugu.setAccountID(accountId);
    window.Iugu.setTestMode(testMode);
    const creditCard = window.Iugu.CreditCard(
      cctoken.number,
      month,
      year,
      firstName,
      lastName,
      cctoken.code,
    );

    return new Promise(async (resolve) => {
      try {
        window.Iugu.createPaymentToken(creditCard, (response) => {
          if (response.errors) {
            Sentry.setContext('createPaymentTokenResponse', { response });
            Sentry.captureMessage('createPaymentToken');
            console.log('createPaymentToken', response.errors);
            resolve('');
          } else {
            resolve(response.id);
          }
        });
      } catch (error) {
        Sentry.setContext('error', { error });
        Sentry.captureMessage('createPaymentToken');
        console.log('createPaymentToken', error);
        resolve('');
      }
    });
  };

  const fillTransaction = (name, value) => {
    setTransaction((prevState) => ({ ...prevState, [name]: value }));
  };

  const fillToken = (name, value) => {
    setCctoken((prevState) => ({ ...prevState, [name]: value }));
  };

  const fillCustomer = (name, value) => {
    setCustomer((prevState) => ({ ...prevState, [name]: value }));
  };

  const fillAttendee = (name, value, index) =>
    setAttendees((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], [name]: value };
      return newState;
    });

  const validations = {
    // attendees: () => validateAttendee(),
    creditCard: () => {
      if (transaction.paymentType === CREDITCARD) {
        return validate(cctoken, cctokenError, 'cctoken');
      }
      return true;
    },
    customer: () => {
      if (transaction.paymentType === FREE) {
        return validate(customer, customerFreeError, 'customer');
      }
      return validate(customer, customerError, 'customer');
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabledButton(true);
    for (let validation in validations) {
      if (!validations[validation]()) {
        setDisabledButton(false);
        return;
      }
    }

    transaction.token = await createPaymentToken();
    transaction.customer = customer;
    transaction.attendees = attendees;
    transaction.attendees[0].dentalmedicine = '';
    transaction.products = cart.map((product) => {
      return { id: product.id, count: product.count };
    });
    transaction.installments =
      transaction.paymentType === CREDITCARD
        ? parseInt(cctoken.installments)
        : 1;

    // console.log(JSON.stringify(transaction));
    setLoading(true);
    const response = await transactions(transaction);

    setPaymentType(transaction.paymentType);
    setTransactionResponse(response);

    const generated = response.success && transaction.paymentType === PIX;
    setRemainingTime(300);
    setShowQrCode(generated);
    setShowSubmitButton(!generated);
    setLoading(false);

    if (transaction.paymentType !== PIX || response.success === false) {
      navigate('/finalizacao');
    }
    setTimeout(() => {
      setDisabledButton(false);
    }, 2000);
  };

  const handlePromoCode = async (e) => {
    e.preventDefault();

    if (currentPromo === '' || promocodeError) {
      return;
    }

    setPromoCodeLoading(true);

    const data = {
      code: currentPromo,
      products: cart.map((product) => {
        return { id: product.id, count: product.count };
      }),
    };

    const promoCalc = await calcCart(data);
    console.log(promoCalc);
    if (promoCalc) {
      const newPrice = Number(promoCalc.installments[1]);
      if (newPrice === currentPrice) {
        setPromoCodeError(true);
        setPromoCodeLoading(false);
        return;
      }
      fillTransaction('promoCode', currentPromo);

      if (newPrice === 0) {
        setFree(true);
        fillTransaction('paymentType', FREE);
      } else {
        setFree(false);
        fillTransaction('paymentType', PIX);
        setNewInstallments(promoCalc.installments);
      }

      fillToken('installments', 1);
      setCurrentPrice(newPrice);
      setPromoCodeLoading(false);
      setCurrentPriceColor('#26a47b');
      setTimeout(() => {
        setCurrentPriceColor('#FFFFFF');
      }, 3000);
    } else {
      setPromoCodeLoading(false);
      setPromoCodeError(true);
    }
  };

  const newAttendee = (attendees) => (
    <>
      <TicketDescription
        isOpen={isOpenModal}
        toggle={toggle}
        urlTicket={urlTicket}
      />
      <Attendee
        key={step - 1}
        index={step - 1}
        attendee={attendees[step - 1]}
        fillAttendee={fillAttendee}
      />
      <Row className='row-btn-submit gap-2'>
        <button
          type='button'
          className='btn-submit'
          onClick={(e) => {
            e.preventDefault();
            if (step === 1) {
              navigate(toHome[currentLanguage]);
            } else {
              prevStep();
            }
          }}
        >
          {t('payment.prev')}
        </button>
        {/* {step > 1 && (
          
        )} */}

        <button
          type='button'
          className='btn-submit'
          onClick={(e) => {
            e.preventDefault();
            if (!validateAttendee(step - 1)) {
              return;
            }
            nextStep();
          }}
        >
          {t('payment.next')}
        </button>
      </Row>
    </>
  );
  const payment = (
    <>
      {!free && (
        <>
          <h3
            className='title2'
            style={{ marginTop: 20 }}
          >
            {t('payment.option.title')}
          </h3>
          <Row>
            <Col className='div-option-payment'>
              <h6>{t('payment.option.pix')}</h6>
              <img
                alt='Forma de pagamento, pix'
                src={PixImg}
                style={{ width: 40, margin: '10px 0' }}
              />
              <input
                id='transaction-payment-type-pix'
                type='radio'
                className='form-check-input'
                value={transaction.paymentType}
                checked={transaction.paymentType === PIX}
                onChange={(e) => {
                  if (transactionResponse.success && paymentType === PIX) {
                    setShowQrCode(true);
                    setShowSubmitButton(false);
                  }
                  fillTransaction('paymentType', PIX);
                }}
              />
              <span className='badge mt-2'>{t('payment.option.pixmsg')}</span>
            </Col>
            <Col className='div-option-payment'>
              <h6>{t('payment.option.creditcard')}</h6>
              <img
                alt='Forma de pagamento, cartão de crédito'
                src={CartoesImg}
                style={{ width: 95, margin: '10px 0' }}
              />
              <input
                id='transaction-payment-type-credit-card'
                type='radio'
                className='form-check-input'
                value={transaction.paymentType}
                checked={transaction.paymentType === CREDITCARD}
                onChange={(e) => {
                  setShowQrCode(false);
                  setShowSubmitButton(true);
                  fillTransaction('paymentType', CREDITCARD);
                }}
              />
              <span className='badge mt-2'>
                {t('payment.option.creditcardmsg')}
              </span>
            </Col>
          </Row>
        </>
      )}

      {transaction.paymentType === CREDITCARD && (
        <Token
          cctoken={cctoken}
          fillToken={fillToken}
          amount={currentPrice}
          maxInstallments={cart[0].installments}
          installments={newInstallments ? newInstallments : installments}
          currentLanguage={currentLanguage}
          setCurrentPrice={setCurrentPrice}
        />
      )}

      <Customer
        customer={customer}
        fillCustomer={fillCustomer}
        free={free}
        paymentType={transaction.paymentType}
      />

      {showSubmitButton && (
        <Row className='row-btn-submit gap-2'>
          <button
            type='button'
            className='btn-submit'
            onClick={(e) => {
              e.preventDefault();
              prevStep();
            }}
          >
            {t('payment.prev')}
          </button>
          <button
            id='btn-buy'
            className='btn-submit'
            onClick={handleSubmit}
            disabled={disabledButton}
          >
            {loading ? (
              <img
                style={{
                  width: '24px',
                }}
                src={Loading}
                alt='Efetuando a transação, aguarde'
              />
            ) : (
              <>{t('cart.buy')}</>
            )}
          </button>
        </Row>
      )}
    </>
  );

  useEffect(() => {
    if (cart?.length === 0) {
      navigate(toHome[currentLanguage]);
    }
    const initialize = async () => {
      const data = {
        code: 'NOCODE',
        products: cart.map((product) => {
          return { id: product.id, count: product.count };
        }),
      };
      const promoCalc = await calcCart(data);

      if (promoCalc) {
        setNewInstallments(promoCalc.installments);
        const newPrice = Number(promoCalc.installments[1]);
        setCurrentPrice(newPrice);
      }

      const isBlockedByAdBlock = window.Iugu.utils.isBlockedByAdBlock();
      setAdBlock(isBlockedByAdBlock);

      let attendees = 0;
      let amount = 0;
      for (let i = 0; i < cart.length; i++) {
        //amount += cart[i].details.price * cart[i].count;
        attendees +=
          cart[i].details.main === true
            ? cart[i].details.attendees
            : cart[i].details.children === true
            ? cart[i].count
            : 0;
      }

      setAttendees(
        Array.from({ length: attendees }, (_, index) => {
          return {
            ...attendeeDefault,
            language: currentLanguage,
            type: index === 0 ? 'C' : 'A',
            dentalmedicine: index === 0 ? 'S' : '',
          };
        }),
      );

      fillTransaction(
        'products',
        cart?.map((ticket) => ticket.id),
      );
    };
    initialize();
  }, [
    navigate,
    selectedProduct.id,
    selectedProduct.price,
    PIX,
    CREDITCARD,
    installments,
  ]);

  const main = {
    1: newAttendee,
    // 2: payment,
  };
  return (
    <section id='section-payment'>
      <Modal
        open={adblock}
        closeModal={(e) => {
          setAdBlock(false);
          navigate(toHome[currentLanguage]);
        }}
      >
        <WarningAdBlock />
      </Modal>
      <Modal
        open={promocodeError}
        closeModal={closeModalPromoCode}
      >
        {t('payment.promocode.title')} {currentPromo}{' '}
        {t('payment.promocode.error')}
      </Modal>

      <Modal
        open={open}
        closeModal={closeModal}
      >
        {message}
      </Modal>

      <Header />
      <Row>
        <Card className='card-payment'>
          <CardBody>
            <Row>
              <Col md={6}>
                <Location />
              </Col>
              <Col md={6}>
                <table>
                  {cart.map((ticket) => (
                    <tr>
                      <td>
                        {ticket?.details?.description[currentLanguage]?.title
                          ? ticket.details.description[currentLanguage].title
                          : ticket.description}
                        {ticket?.details?.description !== undefined && (
                          <>
                            <br />
                            <a
                              href='#'
                              className='ps-0 badge information'
                              onClick={() =>
                                handleOpenModal(
                                  ticket.details.description[currentLanguage]
                                    .url,
                                )
                              }
                            >
                              {ticket.details.description[currentLanguage].text}
                            </a>
                          </>
                        )}
                      </td>
                      <td style={{ textAlign: 'right' }}>{ticket.count}</td>
                      <td style={{ textAlign: 'right' }}>
                        {usdMask(ticket.details.price)}
                      </td>
                    </tr>
                  ))}
                </table>

                <div className='total-amount'>
                  <div className='title2'>{t('payment.amount')}</div>
                  <div style={{ color: currentPriceColor }}>
                    {usdMask(currentPrice)}
                  </div>
                </div>

                {enablePromocode && (
                  <>
                    <div className='total-amount'>
                      <div className='title2'>
                        {t('payment.promocode.title')}
                      </div>
                    </div>
                    {/* <form
                      autoComplete='off'
                      onSubmit={}
                    > */}
                    <div className='total-amount'>
                      <div className='promo-wrapper'>
                        <input
                          type='text'
                          id='current-promo'
                          className='form-control'
                          value={currentPromo}
                          autoComplete='off'
                          onChange={(e) =>
                            setCurrentPromo(e.target.value.trim().toUpperCase())
                          }
                        />
                        <button
                          id='btn-promo-code'
                          className='btn-submit'
                          onClick={(e) => handlePromoCode(e)}
                        >
                          {promocodeLoading ? (
                            <img
                              style={{
                                width: '16px',
                              }}
                              src={Loading}
                              alt='Aplicando cupom, aguarde'
                            />
                          ) : (
                            <>{t('payment.promocode.apply')}</>
                          )}
                        </button>
                      </div>
                    </div>
                    {/* </form> */}
                  </>
                )}
              </Col>
            </Row>
            <form autoComplete='off'>
              {step === attendees.length + 1 && payment}
              {step > 0 && step <= attendees.length && newAttendee(attendees)}
            </form>
            {showQrCode && (
              <Pix
                qrCode={transactionResponse.data?.pix?.qrcode}
                qrCodeText={transactionResponse?.data?.pix?.qrcode_text}
                transactionId={transactionResponse?.data?.transactionId}
              />
            )}
          </CardBody>
        </Card>
      </Row>
    </section>
  );
}
